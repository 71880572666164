'use client'; // This component will handle client-side interactivity

import React, { useEffect, useState } from 'react';
import StarsPercent from '../StarsPercent';
import { convertToKFormat } from '@/services/ecommerce/utils/helper';

// Define the props interface
interface RatingCardProps {
	rating?: number; // Expecting a number for the rating
	totalCount?: number; // Expecting a number for total count of ratings
	scrollSectionIdName?: string;
	istotalCountBlock?: boolean;
	textColor?: string;
	size?: string;
}

const RatingCard: React.FC<RatingCardProps> = ({
	rating,
	totalCount,
	scrollSectionIdName,
	istotalCountBlock = true,
	textColor,
	size
}) => {
	const [currentRating, setCurrentRating] = useState<number>(rating || 0);
  	const [currentTotalCount, setCurrentTotalCount] = useState<number>(totalCount || 0);

	const scrollToReviews = () => {
		const reviewSection = document.getElementById(`${scrollSectionIdName ? scrollSectionIdName : 'productReview'}`);
		if (reviewSection) {
			reviewSection.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		setCurrentRating(rating);
		setCurrentTotalCount(totalCount);
	}, [rating, totalCount]);

	return (
		<div className='d-flex items-center'>
			{istotalCountBlock && (
				<p className='text-14 fw-400 pr-4' style={{ color: `${textColor ? textColor : '#323B49'}` }}>
					{currentRating.toFixed(1)}/5 {/* Format to one decimal place */}
				</p>
			)}
			<StarsPercent rating={currentRating} size={size ? size : '18'} />
			<p
				className='text-14 fw-400 pl-2'
				onClick={scrollToReviews}
				style={{
					color: `${textColor ? textColor : '#0E7673'}`,
					textDecoration: `${istotalCountBlock ? 'underline' : 'none'}`,
					cursor: `${istotalCountBlock ? 'pointer' : 'default'}`
				}}>
				({convertToKFormat(currentTotalCount || 0)}) {istotalCountBlock && 'Ratings'}
			</p>
		</div>
	);
};

export default RatingCard;
