import { IM_BRAND_DEFAULT, IM_BROCKEN, IM_BROKEN_BRAND, IM_PRODUCT_DEFAULT } from '@/assets/images';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import { forOwn, get, has, head, isArray, isEmpty, isObject, isString, split } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import AroggaAPI from '../apis/AroggaAPI';
import { API_ACCOUNT, API_CART } from '../apis/apiConstrant';
import { FLASH_SALE_ID } from '../apis/config';

export const ArrayHasValue = (arr: any[]) => {
	if (!isArray(arr)) {
		return false;
	}
	for (const item of arr) {
		if (item?.vt_title?.trim() && item?.vt_config?.length > 0) {
			return true;
		}
	}
	return false;
};

export const validPhoneNumber = (phone: string) => {
	const regex = /^(\+88|88)?(01[3-9]\d{8})$/;
	return regex.test(phone);
};


export const validateForOtp = (text: string, type: string) => {
	if (type === 'mobile') {
		return validPhoneNumber(text);
	}
	if (type === 'email') {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(text);
	}
	return false;

};
export const getImage = (src: any, type = 'none') => {
	if (src?.length > 0) {
		return src[0].src;
	}
	if (type === 'none') {
		return IM_BROCKEN.src;
	}
	if (type === 'brand') {
		return IM_BRAND_DEFAULT.src;
	}
	if (type === 'brocken_brand') {
		return IM_BROKEN_BRAND.src;
	}
	return IM_BROCKEN.src;
};
export function formatPrice(price) {
	if (price % 1 !== 0) {
		return price.toFixed(2);
	} else {
		return price.toString();
	}
}

//Location Serializers
type LocationData = {
	[division: string]: {
		[district: string]: {
			[area: string]: {
				l_id: number;
			};
		};
	};
};
export const checkIsValidColor = (color) => {
	return color.match(/^#[a-f0-9]{6}$/i) !== null;
};

export const extractLocations = (
	data: LocationData
): {
	divisions: { label: string; value: string; key: string }[];
	districts: { label: string; value: string; key: string }[];
	areas: { label: string; value: string; key: string; id: number }[];
} => {
	const divisions: { label: string; value: string; key: string }[] = [];
	const districts: { label: string; value: string; key: string }[] = [];
	const areas: { label: string; value: string; key: string; id: number }[] = [];

	for (const division in data) {
		divisions.push({ label: division, value: division, key: division });

		for (const district in data[division]) {
			districts.push({ label: district, value: district, key: division });

			for (const area in data[division][district]) {
				areas.push({
					label: area,
					value: area,
					key: district,
					id: data[division][district][area].l_id
				});
			}
		}
	}

	return { divisions, districts, areas };
};

export const hexToRGB = (hex: string): string => {
	let r = 0,
		g = 0,
		b = 0;

	// Handle #3 or #6 digit Hex color
	if (hex.length === 4) {
		r = parseInt(hex[1] + hex[1], 16);
		g = parseInt(hex[2] + hex[2], 16);
		b = parseInt(hex[3] + hex[3], 16);
	} else if (hex.length === 7) {
		r = parseInt(hex[1] + hex[2], 16);
		g = parseInt(hex[3] + hex[4], 16);
		b = parseInt(hex[5] + hex[6], 16);
	} else {
		throw new Error('Invalid hex color code');
	}

	return `${r},${g},${b}`;
};

export const hexToRgbaWithOpacity = (hex, opacity = 1) => {
	if (opacity < 0 || opacity > 1) {
		throw new Error('Opacity must be a value between 0 and 1');
	}

	const r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	return `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;
};
// Product Summary Helper Function for B2B and B2C Product Details Page

type DetailsType = {
	min: number;
	max: number;
	sales_unit: string;
	multiplier: number;
	unit_id: number;
	profit_percent: number;
	mrp: number;
	price: number;
	[key: string]: any;
};
interface RenderLabelType {
	qty: number;
	salesUnit: string;
	baseUnit: string;
	unitMultiplier: number;
}

export const renderLabel = ({ qty, salesUnit, baseUnit, unitMultiplier }: RenderLabelType) => {
	const lables = ['Tablet', 'Capsule', 'Injection'];
	const x = lables.includes(baseUnit) ? ' ' : ' x ';
	const isSameUnit = baseUnit === salesUnit;
	if (salesUnit && unitMultiplier) {
		if (isSameUnit) {
			return `${qty}${x}${pluralize(salesUnit, qty)}`;
		}
		return `${qty * unitMultiplier
			}${x}${pluralize(baseUnit, qty * unitMultiplier)} (${qty} ${pluralize(salesUnit, qty)})`;
	} else {
		return `${qty}`;
	}
};
interface GenerateQuantityOptionsType {
	min: number;
	max: number;
	salesUnit: string;
	multiplier: number;
	baseUnit: string;
}

export const generateQuantityOptions = (
	min,
	max,
	salesUnit,
	unitMultiplier,
	baseUnit
): GenerateQuantityOptionsType[] => {
	const quantityOptions = [] as any[];
	for (let i = min; i <= max; i++) {
		quantityOptions.push({
			value: i,
			label: renderLabel({
				qty: i,
				salesUnit,
				baseUnit,
				unitMultiplier
			})
		});
	}
	return quantityOptions;
};
export const checkDeliveryAndStock = (product: any, variant: any = null) => {
	const v = variant || (isArray(product?.pv) ? head(product?.pv) : product?.pv) || {};
	const isExpress = !!product?.p_allow_sales && !!v?.pv_allow_sales && !!v?.pv_stock_status;
	const stock = !!product?.p_allow_sales && !!v?.pv_allow_sales;
	const delevery = isExpress ? 'Express Delivery' : 'Regular Delivery';
	const description = isExpress
		? '12-24 Hours delivery is available for this item.'
		: '24-48 Hours delivery is available for this item.';
	const short_d_text = isExpress ? '12-24 Hours' : '24-48 Hours';

	return { isExpress, stock, delevery, description, short_d_text };
};

export const ProductSummary = (variant: any, setIsLoading?: any) => {
	const { isB2B } = useAuth();
	const { carts, quantityCache } = useCart();
	const isAlreadyInCert =
		carts?.products?.length > 0 && carts?.products?.find((item: any) => item.pv?.id === variant?.pv_id);
	const userType = isB2B ? 'b2b' : 'b2c';
	const details: DetailsType = {
		min: 0,
		max: 0,
		sales_unit: '',
		multiplier: 1,
		unit_id: 0,
		profit_percent: 0,
		mrp: 0,
		price: 0,
		variant: variant,
		variant_id: variant?.pv_id,
		pu_base_unit_label: variant?.pu_base_unit_label,
		unit_label: '',
		qty: isAlreadyInCert?.qty || 1,
		isVariant: variant ? true : false
	};

	// Define the mapping
	const mapping: Record<string, string> = {
		min: `pv_${userType}_min_qty`,
		max: `pv_${userType}_max_qty`,
		sales_unit: `pu_${userType}_sales_unit_label`,
		multiplier: `pu_${userType}_base_unit_multiplier`,
		unit_id: `pu_${userType}_sales_unit_id`,
		profit_percent: `pv_${userType}_profit_percent`,
		mrp: `pv_${userType}_mrp`,
		price: `pv_${userType}_price`,
		variant: variant,
		pv_stock_status: `pv_stock_status`,
		pu_base_unit_label: `pu_base_unit_label`
	};

	for (const [detailKey, variantKey] of Object.entries(mapping)) {
		if (variant?.hasOwnProperty(variantKey)) {
			const value: any = variant[variantKey];
			details[detailKey as keyof DetailsType] = value;
		}
	}
	details.unit_label = renderLabel({
		qty: isAlreadyInCert?.qty || 1,
		salesUnit: details.sales_unit,
		baseUnit: details.pu_base_unit_label,
		unitMultiplier: details.multiplier
	});

	const getMinMax = async () => {
		try {
			const res = await AroggaAPI.get(API_CART.PRODUCT_VARIANT_MAX_MIN(details?.variant_id));
			if (res?.status === 'success') {
				return res.data;
			}
		} catch (error) {
			console.error('Error fetching min/max values:', error);
			return null;
		}
	};

	const updatedQuantityOptions = async () => {
		if (quantityCache.has(details.variant_id)) {
			const quantity = quantityCache.get(details.variant_id);
			return quantity;
		} else {
			const result = await getMinMax();
			if (result) {
				if (userType === 'b2b') {
					details['min'] = result?.b2b_min_qty;
				} else {
					details['min'] = result?.b2c_min_qty;
				}
			}
			if (result) {
				if (userType === 'b2b') {
					details['max'] = result?.b2b_max_qty;
				} else {
					details['max'] = result?.b2c_max_qty;
				}
			}

			if (!details.max || !details.min) {
				return false;
			}
			const response = Array.from({ length: details.max - details.min + 1 }, (_, index) => {
				const i = details.min + index;
				const multiplierLabel = `${i * details.multiplier} x `;
				return {
					value: i,
					buttonLabel: `${multiplierLabel}${pluralize(details.pu_base_unit_label, i)}`,
					label: renderLabel({
						qty: i,
						salesUnit: details.sales_unit,
						baseUnit: details.pu_base_unit_label,
						unitMultiplier: details.multiplier
					})
				};
			});

			quantityCache.set(details.variant_id, response);

			return response;
		}
	};

	const mrp = Number(details.mrp);
	const price = Number(details.price);

	// Check if mrp and price are valid numbers and mrp is not zero
	if (!isNaN(mrp) && !isNaN(price) && mrp > 0) {
		let discountPercentage = ((mrp - price) / mrp) * 100;
		discountPercentage = Math.round(discountPercentage);
		details.discountPercentage = discountPercentage;
	} else {
		// Handle the case where mrp or price are not valid numbers or mrp is zero
		details.discountPercentage = 0; // or you can assign null or any other error value
	}
	details.price = formatPrice(details.price * details?.qty);
	details.mrp = formatPrice(details.mrp * details?.qty);
	details.updateQuantityOptions = updatedQuantityOptions;

	return details;
};

export const slugify = (input: string): string => {
	return input
		?.toString() // Convert to string
		?.toLowerCase() // Convert to lowercase
		?.trim() // Remove leading and trailing whitespace
		?.replace(/\s+/g, '-') // Replace spaces with hyphens
		?.replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters except hyphens
		?.replace(/\-\-+/g, '-'); // Replace multiple hyphens with a single hyphen
};
export const objectToQueryString = (obj: Record<string, any>): string => {
	if (!obj) {
		return '';
	}
	return Object.keys(obj)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
		.join('&');
};

export const transformSlots = (data) => {
	const slots = [];

	data?.forEach((day) => {
		day.slots.forEach((slot) => {
			const formattedDate = moment(day.date).format('DD MMM YYYY');
			slots.push({
				value: slot.id,
				label: `Delivery ${formattedDate}, between ${slot.slot}`
			});
		});
	});

	return slots;
};

export const searchParamsToObject = (searchParams) => {
	const obj = {};
	for (const [key, value] of searchParams.entries()) {
		obj[key] = value;
	}
	return obj;
};

export const addFilterParams = (links: Record<string, any>, object: Record<string, any>): string => {
	const modifiableSearchParams = new URLSearchParams(links);

	for (const [key, value] of Object.entries(object)) {
		if (value !== undefined && value !== null && value !== '') {
			modifiableSearchParams.set(key, String(value));
		} else {
			modifiableSearchParams.delete(key);
		}
	}

	const updatedParams = modifiableSearchParams.toString();
	return updatedParams;
};
export const newWindowLoad = (
	url = '',
	id = null,
	router = null,
	setIsPayementLoading = null,
	alert = null,
	extraModal = {
		paymentSuccessModal: null,
		setPaymentSuccessModal: null,
		setPaymentSuccessModalData: null
	}
) => {
	const { paymentSuccessModal, setPaymentSuccessModal, setPaymentSuccessModalData } = extraModal;
	if (setIsPayementLoading) {
		setIsPayementLoading(true);
	}
	const width = 900;
	const height = 600;

	const left = window.screenLeft + window.outerWidth / 2 - width / 2;
	const top = window.screenTop + window.outerHeight / 2 - height / 2;
	const options = `status=0,location=0,toolbar=0,menubar=0,width=${width},height=${height},left=${left},top=${top}`;
	const popUp = window.open(url, 'arogga_payment', options);
	const popupTick = setInterval(function () {
		if (!popUp || popUp.closed) {
			clearInterval(popupTick);
			if (setIsPayementLoading) {
				setIsPayementLoading(false);
			}
			if (alert) {
				AroggaAPI.get(API_ACCOUNT.GET_ORDER_DETAIL(id)).then((res) => {
					const { data } = res;
					if (data?.po_payment_status === 'paid') {
						alert.success({
							title: 'Payment Success',
							message: 'We have received your payment successfully.',
							confirmText: 'Okay',
							onConfirm: () => {
								setPaymentSuccessModalData && setPaymentSuccessModalData(data);
								setPaymentSuccessModal && setPaymentSuccessModal(true);
							}
						});
					} else {
						alert.error({
							title: 'OPPS!',
							message: 'Your payment is not completed if you successfully paid please wait for a while'
						});
					}
				});
			}
		}
	}, 500);

	return false;
};

export const cleanObject = (obj) => {
	return Object.entries(obj).reduce((acc, [key, value]) => {
		// Check for non-null, non-undefined, and non-empty string values
		if (value !== null && value !== undefined && value !== '') {
			acc[key] = value;
		}
		return acc;
	}, {});
};

export const getProductImage = (data: any) => {
	const product_type = get(data, 'p_type', 'medicine');
	const type = product_type === 'pet_&_vet' ? 'pet_and_vet' : product_type;
	const pvSrc = get(data, 'pv[0].attachedFiles_pv_images[0].src', get(data, 'pv.attachedFiles_pv_images[0].src', ''));

	return pvSrc || get(data, 'attachedFiles_p_images[0].src', IM_PRODUCT_DEFAULT[type]);
};

export function extractTitles(obj) {
	let titles = [] as any[];
	forOwn(obj, (value, key) => {
		if (key === 'title') {
			titles.push(value);
		} else if (isObject(value)) {
			titles = titles.concat(extractTitles(value));
		}
	});
	return titles;
}

export const getRawTextFromHtml = (str) => {
	const regex = /(<([^>]+)>)/gi;
	return str?.replace(regex, '');
};

export const reverseSlugify = (slug) => {
	// Replace hyphens/underscores with spaces and split the string
	const words = slug?.replace(/[-_]+/g, ' ').split(' ');

	// Capitalize the first letter of each word and join them back together
	return words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ') || '';
};
export const textToSlug = (text: any): string =>
	text
		.toString()
		.trim()
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '');

export const titleToSlug = (brand: { name?: string; form?: string; strength?: string }): string => {
	const text = `${brand.name || ''}-${brand.form || ''}-${brand.strength || ''}`;
	return textToSlug(text);
};

export const generateProductLink = (item: any, variant = {} as any, pv = true) => {
	const attributes = get(variant, 'pv_attribute', {});
	const isMultiVariant = Object.keys(attributes).length > 0;
	const params = objectToQueryString({
		// ...attributes,
		...(pv && { pv_id: variant?.pv_id })
		// ...({ pv_id: variant?.pv_id })
		// ...(isMultiVariant && { pv_id: variant?.pv_id })
	});
	const id = item?.p_id;
	const productLink = `/product/${id}/${titleToSlug({ name: item?.p_name, form: item?.p_form, strength: item?.p_strength })}${params ? `?${params}` : ''}`;

	return productLink;
};

export const factCheck = (newFacts, setFacts) => {
	// console.log(newFacts);
	const isFormFactsEmpty = isEmpty(newFacts?._form);
	const isBrandFactsEmpty = isEmpty(newFacts?._brand_id);
	const isTagsFactsEmpty = isEmpty(newFacts?._tags);

	setFacts((prevFacts) => ({
		...prevFacts,
		_brand_id: mergeBrandData(prevFacts._brand_id, newFacts?._brand_id, isBrandFactsEmpty),
		_form: mergeUniqueData(prevFacts._form, newFacts?._form, isFormFactsEmpty),
		_tags: mergeUniqueData(prevFacts._tags, newFacts?._tags, isTagsFactsEmpty),
		pv_b2b_price: mergeUniquePriceData(prevFacts.pv_b2b_price, newFacts?.pv_b2b_price),
		pv_b2c_price: mergeUniquePriceData(prevFacts.pv_b2c_price, newFacts?.pv_b2c_price),
	}));
};

const mergeUniquePriceData = (prevData, newData) => {
	// Check if newData is empty or null
	if (isEmpty(newData)) {
		return prevData || { min: 0, max: 0 };
	}

	// Initialize mergedData with a minimum value of 0 and the previous maximum value
	const mergedData = {
		min: prevData?.min ?? 0,
		max: prevData?.max ?? 0
	};

	// Update the max value if the new max is greater than the previous max
	if (!isNaN(newData.max) && newData.max > mergedData.max) {
		mergedData.max = newData.max;
	}
	if (!isNaN(newData.min) && newData.min < mergedData.min) {
		mergedData.min = newData.min;
	}

	return mergedData;
};

const mergeBrandData = (prevData, newData, reset) => {
	if (isEmpty(newData)) {
		if (reset) {
			//reset the brand data total to 0
			return prevData.map((item) => ({ ...item, total: 0 }));
		}
		return prevData || [];
	}

	// Create a map from the existing data for quick lookup
	const existingDataMap = new Map(prevData.map((item) => [item.id, item])) as Map<string, any>;

	// Merge and de-duplicate data
	newData.forEach((item: any) => {
		if (!existingDataMap.has(item.id)) {
			existingDataMap.set(item.id, item);
		} else {
			existingDataMap.get(item.id).total = item.total;
		}
	});

	return Array.from(existingDataMap.values());
};
const mergeUniqueData = (prevData, newData, reset) => {
	if (isEmpty(newData)) {
		if (reset) {
			return prevData?.map((item) => ({ ...item, total: 0 }));
		}
		return prevData || [];
	}

	const updatedData = Object.keys(newData).map((key) => ({
		name: key,
		id: key,
		total: newData[key]
	}));

	// Assuming the unique identifier is 'id'
	const uniqueData = new Map([...(prevData || []), ...updatedData].map((item) => [item.id, item]));
	return Array.from(uniqueData.values());
};

export function commaToArraySafe(input) {
	if (isString(input)) {
		// Use lodash's split function to convert the string to an array
		return split(input, ',');
	} else {
		// Handle unexpected input: return an empty array or throw an error
		console.error('Input is not a string.');
		return []; // or throw new Error('Input is not a string.');
	}
}
export function getMobileOperatingSystem() {
	if (typeof navigator === 'undefined') {
		return 'unknown';
	}
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return 'Windows Phone';
	}

	if (/android/i.test(userAgent)) {
		return 'Android';
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return 'iOS';
	}

	return 'unknown';
}
export const checkforwardedIps = (forwardedIps) => {
	if (forwardedIps?.indexOf(',') > -1) {
		forwardedIps = forwardedIps.split(',')[0];
	}
	return forwardedIps;
};

export const serializeOrdersParams = (searchParams) => {
	const checkOrderKey = has(searchParams, '_order');
	if (checkOrderKey && !get(searchParams, '_order', '').trim()) {
		return '';
	}
	const default_orders = new Set(['pv_allow_sales:desc', 'productCountOrdered:desc']);
	const searchParamsKeys = get(searchParams, '_order', '').split(',');
	let finalOrder = new Set(searchParamsKeys);
	finalOrder = new Set([...finalOrder].filter((item) => item));
	return finalOrder.size > 0 ? Array.from(finalOrder).join(',') : Array.from(default_orders).join(',');
};

export const convertToKFormat = (count: number): string => {
    if (count >= 1000) {
        return `${(count / 1000).toFixed(1)}k`; // Format as 1.0k
    }
    return count ? Number.isInteger(Number(count)) ? Number(count).toString() : Number(count).toFixed(1) : "0";
};
export const isFlashSale = (variant) => {
	return parseInt(get(variant, 'pv_campaign_tag_id', 0)) === parseInt(FLASH_SALE_ID);
};